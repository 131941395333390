import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import Farm from './components/Farm/Farm';
import Tasks from './components/Tasks/Tasks';
import Boost from './components/Boost/Boost';
import Frens from './components/Frens/Frens';
import Navigation from './components/Navigation/Navigation';
import SplashScreen from './components/SplashScreen/SplashScreen';
import DesktopScreen from './components/DesktopScreen/DesktopScreen';
import {TokenProvider, useToken} from './contexts/TokenContext';
import {ToastContainer} from 'react-toastify';

import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import {useDailyLoginBonus} from './hooks/useDailyLoginBonus';
import DailyLoginBonus from './components/DailyLoginBonus/DailyLoginBonus';
import {UserPointsProvider} from './contexts/UserPointsContext';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const AppContent: React.FC = () => {
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const [isDesktop, setIsDesktop] = useState(false);
    const {setToken} = useToken();
    const query = useQuery();
    const { showLoginBonus, loginStreak, closeLoginBonus } = useDailyLoginBonus();

    const [showDebug, setShowDebug] = useState(false);

    const toggleDebug = () => setShowDebug(!showDebug);
    useEffect(() => {
        // Try to get token from query string first
        const queryToken = query.get('token');

        if (queryToken) {
            // If token is in query string, save it to localStorage and set it in context
            localStorage.setItem('chimpToken', queryToken);
            setToken(queryToken);
        } else {
            // If no token in query string, try to get it from localStorage
            const storedToken = localStorage.getItem('chimpToken');
            if (storedToken) {
                setToken(storedToken);
            }
        }
    }, [query]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsSplashVisible(false);
        }, 3000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const telegramWebApp = window.Telegram?.WebApp;

        if (telegramWebApp) {
            telegramWebApp.expand();
            telegramWebApp.disableVerticalSwipes();

            const isDebug = !!process.env.REACT_APP_IS_DEBUG;
            const notMobilePlatform = telegramWebApp.platform !== 'ios' && telegramWebApp.platform !== 'android';

            if (!isDebug && notMobilePlatform) {
                setIsDesktop(true);
            }
        }
    }, []);

    if (isDesktop) {
        return <DesktopScreen/>;
    }

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {isSplashVisible ? (
                <SplashScreen/>
            ) : (
                <>
                    {showLoginBonus && (
                        <DailyLoginBonus streak={loginStreak} onClose={closeLoginBonus}/>
                    )}
                    <Routes>
                        <Route path="/tasks" element={<Tasks/>}/>
                        <Route path="/farm" element={<Farm/>}/>
                        <Route path="/boost" element={<Boost/>}/>
                        <Route path="/frens" element={<Frens/>}/>
                        <Route path="/" element={<Farm/>}/>
                    </Routes>
                    <Navigation/>
                </>
            )}
        </div>
    );
};

const App: React.FC = () => {
    return (
        <TokenProvider>
        <UserPointsProvider>
                <Router>
                    <AppContent />
                </Router>
            </UserPointsProvider>
        </TokenProvider>
    );
};

export default App;
