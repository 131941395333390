import React from 'react';
import chimpSmall from '../../assets/images/chimp-small.png';
import checkmark from '../../assets/images/checkmark.png';

interface TaskItemProps {
    name: string;
    description: string;
    reward: number;
    completed: boolean;
    social_url: string;
    icon: string;
    onClick: () => void;
}

const TaskItem: React.FC<TaskItemProps> = ({name, description, reward, completed, social_url, icon, onClick}) => {
    const handleClick = () => {
        if (!completed) {
            window.open(social_url, '_blank');
            setTimeout(() => {
                onClick();
            }, 5000);
        }
    };

    return (
        <div
            className={`tasks__item ${completed ? 'completed disabled' : ''}`}
            onClick={handleClick}
        >
            <img className="tasks__item__icon" src={`https://${icon}`} alt={name}/>
            <div className="tasks__item__text">
                <p>{description}</p>
                {!completed && (
                    <p>
                        + {reward} <img className="tasks__item__token" src={chimpSmall} alt="Chimp small"/>
                    </p>
                )}
            </div>
            <img className="tasks__item__checkmark" src={checkmark} alt="Checkmark"/>
        </div>
    );
};

export default TaskItem;
