import { useState, useEffect, useRef, useCallback } from 'react';
import { FallingImage, TextAnimation } from './types';
import { createFallingImage } from './utils';
import { useUserPoints } from '../../contexts/UserPointsContext';

export const useGameLogic = (
    maxEnergy: number,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    axiosInstance: any,
    initialTotalScore: number,
    energyRefillRate: number,
    initialEnergy: number,
    multiplier: number
) => {
    const [currentUserScore, setCurrentUserScore] = useState(0);
    const [currentEnergy, setCurrentEnergy] = useState(initialEnergy);
    const [fallingImages, setFallingImages] = useState<FallingImage[]>([]);
    const [textAnimations, setTextAnimations] = useState<TextAnimation[]>([]);
    const [specialCoinsCollected, setSpecialCoinsCollected] = useState(() => {
        const storedCoins = localStorage.getItem('specialCoinsCollected');
        return storedCoins ? parseInt(storedCoins, 10) : 0;
    });
    const [isTabVisible, setIsTabVisible] = useState(true);
    const currentEnergyRef = useRef(currentEnergy);
    const fallingImagesRef = useRef(fallingImages);
    const textAnimationsRef = useRef(textAnimations);
    const animationFrameId = useRef<number | null>(null);
    const totalScoreRef = useRef(initialTotalScore);
    const currentUserScoreRef = useRef(currentUserScore);
    const generationIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const restoreIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const { updatePoints, points } = useUserPoints();

    useEffect(() => {
        setCurrentEnergy(initialEnergy);
    }, [initialEnergy]);

    useEffect(() => {
        totalScoreRef.current = initialTotalScore;
    }, [initialTotalScore]);

    useEffect(() => {
        currentUserScoreRef.current = currentUserScore;
    }, [currentUserScore]);

    useEffect(() => {
        currentEnergyRef.current = currentEnergy;
    }, [currentEnergy]);

    useEffect(() => {
        fallingImagesRef.current = fallingImages;
    }, [fallingImages]);

    useEffect(() => {
        textAnimationsRef.current = textAnimations;
    }, [textAnimations]);

    useEffect(() => {
        localStorage.setItem('specialCoinsCollected', specialCoinsCollected.toString());
    }, [specialCoinsCollected]);

    const pointsRef = useRef(points);

    useEffect(() => {
        pointsRef.current = points;
    }, [points]);

    const updateUserGame = useCallback(async () => {
        try {
            const updatedTotalScore = pointsRef.current + currentUserScoreRef.current;
            const currentEnergy = currentEnergyRef.current;

            // Check if there's any change in points or energy
            if (updatedTotalScore === pointsRef.current && currentEnergy === initialEnergy) {
                return;
            }

            const patchData: { points?: number; energy?: number } = {};

            // Only include points in the patch if they've changed
            if (updatedTotalScore !== pointsRef.current) {
                patchData.points = updatedTotalScore;
            }

            // Only include energy in the patch if it's changed from the initial value
            if (currentEnergy !== initialEnergy) {
                patchData.energy = currentEnergy;
            }

            // Only send the PATCH request if there's data to update
            if (Object.keys(patchData).length > 0) {
                const response = await axiosInstance.patch('/user/game', patchData);

                if (response.data && response.data.points) {
                    updatePoints(response.data.points);
                }
            }
        } catch (error) {
            console.error('Error updating user game data:', error);
        }
    }, [axiosInstance, updatePoints, initialEnergy]);

    const generateFallingImages = useCallback(() => {
        if (!isTabVisible) return;

        const maxPossibleImages = Math.floor(currentEnergyRef.current / multiplier);
        let numImages = Math.min(Math.floor(Math.random() * 5) + 1, maxPossibleImages);
        if (fallingImagesRef.current.length + numImages > maxPossibleImages) {
            numImages = maxPossibleImages - fallingImagesRef.current.length;
        }
        if (numImages > 0) {
            const newImages = Array.from({ length: numImages }, () =>
                createFallingImage(canvasRef.current?.width || 0)
            );
            setFallingImages(prevImages => [...prevImages, ...newImages]);
        }
    }, [canvasRef, multiplier, isTabVisible]);

    const restoreEnergy = useCallback(() => {
        if (!isTabVisible) return;

        if (currentEnergyRef.current < maxEnergy) {
            setCurrentEnergy(prevEnergy => Math.min(prevEnergy + energyRefillRate, maxEnergy));
        }
    }, [maxEnergy, energyRefillRate, isTabVisible]);

    const gameLoop = useCallback(() => {
        if (!isTabVisible) return;

        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        const updatedFallingImages = fallingImagesRef.current.filter(img => {
            if (!img.loaded && img.image.complete) {
                img.loaded = true;
            }
            img.update(canvas.width);
            img.draw(ctx);
            return img.y <= canvas.height;
        });

        const updatedTextAnimations = textAnimationsRef.current
            .map(animation => {
                ctx.font = 'bold 28px Arial, sans-serif';
                let fillColor;
                if (animation.color === 'gold') {
                    fillColor = `rgba(255, 215, 0, ${animation.opacity})`;
                } else if (animation.color === '#0098e9') {
                    fillColor = `rgba(0, 152, 233, ${animation.opacity})`;
                } else {
                    fillColor = `rgba(255, 0, 0, ${animation.opacity})`;
                }
                ctx.fillStyle = fillColor;
                ctx.strokeStyle = `rgba(0, 0, 0, ${animation.opacity})`;
                ctx.lineWidth = 2;
                ctx.textAlign = 'center';
                ctx.textBaseline = 'middle';

                ctx.strokeText(animation.text, animation.x, animation.y);
                ctx.fillText(animation.text, animation.x, animation.y);

                return { ...animation, y: animation.y - 1.5, opacity: animation.opacity - 0.02 };
            })
            .filter(animation => animation.opacity > 0);

        setFallingImages(updatedFallingImages);
        setTextAnimations(updatedTextAnimations);

        animationFrameId.current = requestAnimationFrame(gameLoop);
    }, [canvasRef, isTabVisible]);

    const handleVisibilityChange = useCallback(() => {
        setIsTabVisible(!document.hidden);
    }, []);

    useEffect(() => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const resizeCanvas = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight - 40 - 220;
        };

        window.addEventListener('resize', resizeCanvas);
        document.addEventListener("visibilitychange", handleVisibilityChange);
        resizeCanvas();

        if (isTabVisible) {
            gameLoop();
            generationIntervalRef.current = setInterval(generateFallingImages, 1000);
            restoreIntervalRef.current = setInterval(restoreEnergy, 3000);
        } else {
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            if (generationIntervalRef.current) {
                clearInterval(generationIntervalRef.current);
            }
            if (restoreIntervalRef.current) {
                clearInterval(restoreIntervalRef.current);
            }
        }

        return () => {
            window.removeEventListener('resize', resizeCanvas);
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            if (animationFrameId.current) {
                cancelAnimationFrame(animationFrameId.current);
            }
            if (generationIntervalRef.current) {
                clearInterval(generationIntervalRef.current);
            }
            if (restoreIntervalRef.current) {
                clearInterval(restoreIntervalRef.current);
            }
        };
    }, [canvasRef, gameLoop, generateFallingImages, restoreEnergy, handleVisibilityChange, isTabVisible]);

    const sliceImage = useCallback((x: number, y: number) => {
        console.log('Slicing image at:', x, y);
        setFallingImages(prevImages =>
            prevImages.filter(img => {
                if (
                    x >= img.x && x <= img.x + 100 &&
                    y >= img.y && y <= img.y + 100
                ) {
                    const pointsGained = img.isSpecialToken ? multiplier * 10 : multiplier;
                    setCurrentUserScore(prevScore => prevScore + pointsGained);
                    setCurrentEnergy(prevEnergy => Math.max(prevEnergy - multiplier, 0));

                    if (img.isSpecialToken) {
                        const newSpecialCoinsCount = specialCoinsCollected + 1;
                        setSpecialCoinsCollected(newSpecialCoinsCount);
                        localStorage.setItem('specialCoinsCollected', newSpecialCoinsCount.toString());
                    }

                    setTextAnimations(prevAnimations => [
                        ...prevAnimations,
                        {
                            x: img.x + 50,
                            y: img.y + 50,
                            text: `+${pointsGained}`,
                            opacity: 1,
                            color: img.isSpecialToken ? '#0098e9' : 'gold'
                        }
                    ]);

                    return false;
                }
                return true;
            })
        );
    }, [multiplier, specialCoinsCollected]);

    const resetSpecialCoinsCollected = useCallback(() => {
        setSpecialCoinsCollected(0);
        localStorage.setItem('specialCoinsCollected', '0');
        console.log('Red coins reset to 0');
    }, []);

    return {
        currentUserScore,
        currentEnergy,
        sliceImage,
        setCurrentUserScore,
        updateUserGame,
        fallingImages,
        textAnimations,
        specialCoinsCollected,
        resetSpecialCoinsCollected,
        isTabVisible
    };
};
