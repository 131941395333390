import React, {useState, useEffect, useCallback, useRef} from 'react';
import './TapChallenge.scss';
import tokenClick from '../../assets/images/taptap.png';

interface TapChallengeProps {
    onFinish: (score: number) => void;
    multiplier: number;
}

const TapChallenge: React.FC<TapChallengeProps> = ({onFinish, multiplier}) => {
    const [tapCount, setTapCount] = useState(0);
    const [timeLeft, setTimeLeft] = useState(15);
    const [isGameActive, setIsGameActive] = useState(true);
    const tokenRef = useRef<HTMLImageElement>(null);

    const specialMultiplier = 10 * multiplier;

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft((prevTime) => {
                if (prevTime <= 1) {
                    clearInterval(timer);
                    setIsGameActive(false);
                    return 0;
                }
                return prevTime - 1;
            });
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const handleTap = useCallback(() => {
        if (isGameActive) {
            setTapCount((prevCount) => prevCount + 1);

            // Provide immediate visual feedback
            if (tokenRef.current) {
                tokenRef.current.style.transform = 'scale(0.9)';
                setTimeout(() => {
                    if (tokenRef.current) {
                        tokenRef.current.style.transform = 'scale(1)';
                    }
                }, 50);
            }
        }
    }, [isGameActive]);

    return (
        <div className="tap-challenge">
            <div className="tap-challenge__info">
                {!isGameActive ? (
                    <div className="tap-challenge__game-over">
                        <h2>Congratulations!</h2>
                        <p>Your score is : {tapCount} taps, which gets you {tapCount * specialMultiplier * 10} extra
                            points!</p>
                    </div>
                ) : (
                    <>
                        <h2>Taps: {tapCount}</h2>
                    </>
                )}
            </div>
            <div
                className={`tap-challenge__token ${!isGameActive ? 'disabled' : ''}`}
                onClick={handleTap}
            >
                <img
                    ref={tokenRef}
                    src={tokenClick}
                    alt="Special Token"
                    className="tap-challenge__token-image"
                />
            </div>
            <div className="tap-challenge__footer">
                {isGameActive ? (
                    <p className="tap-challenge__time-left">Time left: {timeLeft} seconds</p>
                ) : (
                    <div className="tap-challenge__game-over-button">
                        <button onClick={() => onFinish(tapCount * specialMultiplier * 10)}>Close</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TapChallenge;
