import React, { useState, useEffect } from 'react';
import chimpMedium from '../../assets/images/chimp-medium.png';
import './Tasks.scss';
import { useAxios } from '../../hooks/useAxios';
import TaskItem from './TaskItem';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import {useUserPoints} from '../../contexts/UserPointsContext';

interface Task {
    name: string;
    description: string;
    reward: number;
    completed: boolean;
    social_url: string;
    icon: string;
}

interface TaskResponse {
    tasks: Task[];
}

interface User {
    id: string;
    name: string;
    points: number;
}

const Tasks: React.FC = () => {
    const axiosInstance = useAxios();
    const [user, setUser] = useState<User | null>(null);
    const [tasks, setTasks] = useState<TaskResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [completedTasks, setCompletedTasks] = useState<{ [key: string]: boolean }>({});

    const { updatePoints } = useUserPoints();

    useEffect(() => {
        const fetchUserAndTasks = async () => {
            try {
                const userResponse = await axiosInstance.get<User>('/user');
                setUser(userResponse.data);
                updatePoints(userResponse.data.points);

                const tasksResponse = await axiosInstance.get<TaskResponse>('/user/task/all');
                setTasks(tasksResponse.data);
                setCompletedTasks(tasksResponse.data.tasks.reduce((acc, task) => {
                    acc[task.name] = task.completed;
                    return acc;
                }, {} as { [key: string]: boolean }));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                toast.error('Failed to load data.');
                setLoading(false);
            }
        };

        void fetchUserAndTasks();
    }, [axiosInstance, updatePoints]);

    const handleItemClick = async (task: string) => {
        try {
            const response = await axiosInstance.post('/user/task/completed', { name: task });
            setCompletedTasks(prevState => ({
                ...prevState,
                [task]: true
            }));
            console.log(`Task ${task} marked as completed.`);
            toast.success(`Task completed.`);

            // Update points after completing a task
            if (response.data && response.data.points) {
                updatePoints(response.data.points);
            }
        } catch (error) {
            console.error('Error marking task as completed:', error);
            toast.error('Failed to mark task as completed.');
        }
    };

    if (loading) {
        return (
            <div className="loading-spinner">
                <ClipLoader size={150} color='#FAC22F'/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-message">
                Something went wrong, please contact support.
            </div>
        );
    }

    return (
        <div className="tasks">
            <img className="tasks__logo" src={chimpMedium} alt="Chimp large" />
            <h1 className="tasks__title">Earn more $CHIMP Token Airdrop</h1>
            <div className="tasks__content">
                {tasks?.tasks.map(task => (
                    <TaskItem
                        key={task.name}
                        name={task.name}
                        description={task.description}
                        reward={task.reward}
                        completed={completedTasks[task.name]}
                        social_url={task.social_url}
                        icon={task.icon}
                        onClick={() => handleItemClick(task.name)}
                    />
                ))}
            </div>
        </div>
    );
};

export default Tasks;
