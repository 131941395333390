import React from 'react';
import './Score.scss';
import chimpLogo from '../../assets/images/farm.png';
import specialCoinIcon from '../../assets/images/TokenSpecial.svg';
import { useUserPoints } from '../../contexts/UserPointsContext';

interface ScoreProps {
    specialCoinsCollected?: number;
    currentUserScore?: number;
}

const Score: React.FC<ScoreProps> = ({ specialCoinsCollected, currentUserScore }) => {

    const { points } = useUserPoints();
    return (
        <div className="score">
            {specialCoinsCollected !== undefined && (
                <div className="score__special-coins">
                    <img src={specialCoinIcon} alt="Red Coin" className="score__special-coin-icon" />
                    <span className="score__special-coin-count">{specialCoinsCollected} / <span className="text-white">10</span></span>
                </div>
            )}
            <div className="score__center">
                <img src={chimpLogo} alt="Logo" className="score__logo" />
                <div className="score__score">{points + (currentUserScore || 0)}</div>
            </div>
            {specialCoinsCollected !== undefined && <div className="score__placeholder"></div>}
        </div>
    );
};

export default React.memo(Score);

