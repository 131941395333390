import axios from 'axios';
import { useToken } from '../contexts/TokenContext';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

export const useAxios = () => {
    const { token } = useToken();

    axiosInstance.interceptors.request.use(
        (config) => {
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    return axiosInstance;
};
