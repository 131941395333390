import React from 'react';
import './LoadingError.scss';
import { ClipLoader } from 'react-spinners';

interface LoadingErrorProps {
    loading: boolean;
    error: any;
}

const LoadingError: React.FC<LoadingErrorProps> = ({ loading, error }) => {

    if (loading) {
        return (
            <div className="loading-spinner">
                <ClipLoader size={150} color='#FAC22F' />
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-message">
                Something went wrong, please contact support.
            </div>
        );
    }

    return null;
};

export default LoadingError;
