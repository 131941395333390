import { useState, useEffect, useCallback } from 'react';
import { useAxios } from './useAxios';
import { useUserPoints } from '../contexts/UserPointsContext';

const bonusAmounts = [1000, 2500, 5000, 10000, 20000];

export const useDailyLoginBonus = () => {
    const [showLoginBonus, setShowLoginBonus] = useState(false);
    const [loginStreak, setLoginStreak] = useState(0);
    const axiosInstance = useAxios();
    const { updatePoints } = useUserPoints();

    useEffect(() => {
        const lastLoginDate = localStorage.getItem('lastLoginDate');
        const today = new Date().toDateString();
        const yesterday = new Date(Date.now() - 86400000).toDateString();

        if (lastLoginDate !== today) {
            if (lastLoginDate === yesterday) {
                const streak = Number(localStorage.getItem('loginStreak') || '0');
                setLoginStreak(streak + 1);
            } else {
                setLoginStreak(1);
            }
            setShowLoginBonus(true);
        }
    }, []);

    const closeLoginBonus = useCallback(async () => {
        const today = new Date().toDateString();
        localStorage.setItem('lastLoginDate', today);
        localStorage.setItem('loginStreak', loginStreak.toString());

        const bonusIndex = Math.min(loginStreak - 1, bonusAmounts.length - 1);
        const bonusPoints = bonusAmounts[bonusIndex];

        try {
            const userResponse = await axiosInstance.get('/user/game');
            const currentPoints = userResponse.data.points;
            const newPoints = currentPoints + bonusPoints;

            await axiosInstance.patch('/user/game', {
                points: newPoints,
            });
            console.log(`Updated user's score from ${currentPoints} to ${newPoints}`);

            updatePoints(newPoints);
        } catch (error) {
            console.error('Error updating user game data:', error);
        }

        setShowLoginBonus(false);
    }, [loginStreak, axiosInstance, updatePoints]);

    return { showLoginBonus, loginStreak, closeLoginBonus };
};
