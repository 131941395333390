import React, { useEffect, useState } from 'react';
import './DesktopScreen.scss';
import desktopScreen from '../../assets/images/desktop-screen.png';
import highResImage from '../../assets/images/splash.png';

const DesktopScreen: React.FC = () => {
    return (
        <div
            className="desktop-screen"
            style={{
                backgroundImage: `url(${desktopScreen})`,
            }}
        >
        </div>
    );
};

export default DesktopScreen;
