import React, { createContext, useState, useContext, useCallback } from 'react';

interface UserPointsContextType {
    points: number;
    updatePoints: (newPoints: number) => void;
}

const UserPointsContext = createContext<UserPointsContextType | undefined>(undefined);

export const UserPointsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [points, setPoints] = useState(0);

    const updatePoints = useCallback((newPoints: number) => {
        setPoints(newPoints);
    }, []);

    return (
        <UserPointsContext.Provider value={{ points, updatePoints }}>
            {children}
        </UserPointsContext.Provider>
    );
};

export const useUserPoints = () => {
    const context = useContext(UserPointsContext);
    if (context === undefined) {
        throw new Error('useUserPoints must be used within a UserPointsProvider');
    }
    return context;
};
