import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Score from '../Score/Score';
import multiplier from '../../assets/images/multiplier.png';
import specialGame from '../../assets/images/special-game.png';
import increase from '../../assets/images/increase.png';
import refill from '../../assets/images/refill.png';
import energyRefill from '../../assets/images/energy-refill.png';
import chimpSmall from '../../assets/images/chimp-small.png';
import { useAxios } from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { ClipLoader } from 'react-spinners';
import './Boost.scss';
import { useUserPoints } from '../../contexts/UserPointsContext';

enum DailyBoosterName {
    SPECIAL_GAME = 'Special Game',
    ENERGY_REFILL = 'Max Bananas'
}

enum PermanentBoosterName {
    TOKEN_MULTIPLIER = 'Token Multiplier',
    INCREASE_MAX_BANANAS = 'Increase Max Bananas',
    BANANA_REFILL = 'Increase Banana Refill Rate'
}

interface DailyBooster {
    name: DailyBoosterName;
    remaining: number;
    total: number;
    disabled?: boolean;
}

interface PermanentBooster {
    name: PermanentBoosterName;
    level: number;
    price: number;
    upgradeValue: number;
}

interface BoostersResponse {
    boosters: {
        daily: DailyBooster[];
        permanent: PermanentBooster[];
    };
    points: number;
}

interface BoostResponse {
    message: string;
}

const Boost: React.FC = () => {
    const axios = useAxios();
    const navigate = useNavigate();
    const [data, setData] = useState<BoostersResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activatingBooster, setActivatingBooster] = useState<DailyBoosterName | PermanentBoosterName | null>(null);

    const { updatePoints } = useUserPoints();

    const fetchBoosters = async () => {
        try {
            const response = await axios.get<BoostersResponse>('/user/booster/all');
            setData(response.data);
            updatePoints(response.data.points);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error instanceof AxiosError && error.response) {
                setError(error.response.data);
            } else {
                setError('Failed to load boosters');
            }
            toast.error('Failed to load boosters');
            setLoading(false);
        }
    };

    useEffect(() => {
        void fetchBoosters();
    }, []);

    const handleActivateDaily = async (name: DailyBoosterName) => {
        setActivatingBooster(name);
        try {
            const response = await axios.post<BoostResponse>('/user/booster/daily/activate', { name });
            if (name !== DailyBoosterName.SPECIAL_GAME) {
                toast.success(response.data.message);
            }
            await fetchBoosters();
            if (name === DailyBoosterName.SPECIAL_GAME) {
                navigate('/farm?activateSpecialGame=true');
            }
        } catch (error) {
            console.error('Error activating daily booster:', error);
            if (error instanceof AxiosError && error.response) {
                toast.error(error.response.data);
            } else {
                toast.error('Failed to activate daily booster.');
            }
        } finally {
            setActivatingBooster(null);
        }
    };

    const handleActivatePermanent = async (name: PermanentBoosterName) => {
        setActivatingBooster(name);
        try {
            const response = await axios.post<BoostResponse>('/user/booster/upgrade', { name });
            toast.success(response.data.message);
            await fetchBoosters();
        } catch (error) {
            console.error('Error upgrading permanent booster:', error);
            if (error instanceof AxiosError && error.response) {
                toast.error(error.response.data);
            } else {
                toast.error('Failed to upgrade permanent booster.');
            }
        } finally {
            setActivatingBooster(null);
        }
    };

    const getDailyBoosterIcon = (boosterName: DailyBoosterName) => {
        switch (boosterName) {
            case DailyBoosterName.SPECIAL_GAME:
                return specialGame;
            case DailyBoosterName.ENERGY_REFILL:
                return energyRefill;
            default:
                return specialGame; // Default icon if name doesn't match
        }
    };

    const getDailyBoosterDisplayName = (booster: DailyBooster) => {
        if (booster.name === DailyBoosterName.SPECIAL_GAME && booster.disabled) {
            return 'Coming Soon';
        }
        return booster.name;
    };

    const getDailyBoosterCount = (booster: DailyBooster) => {
        if (booster.name === DailyBoosterName.SPECIAL_GAME && booster.disabled) {
            return '';
        }
        return ` ${booster.remaining}/${booster.total}`;
    };

    if (loading) {
        return (
            <div className="loading-spinner">
                <ClipLoader size={150} color='#FAC22F'/>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-message">
                Something went wrong, please contact support.
            </div>
        );
    }

    return (
        <div className="boost">
            <Score />
            <p className="boost__title">Free Daily Boosters</p>
            <div className="boost__daily">
                {data?.boosters.daily.map((booster) => (
                    <div
                        key={booster.name}
                        className={`boost__item ${(booster.remaining === 0 || booster.disabled) ? 'disabled' : ''} ${activatingBooster === booster.name ? 'activating' : ''}`}
                        onClick={() => booster.remaining > 0 && !booster.disabled && handleActivateDaily(booster.name)}
                    >
                        <img
                            className={`boost__item__icon ${booster.remaining === 0 || booster.disabled ? 'disabled' : ''}`}
                            src={getDailyBoosterIcon(booster.name)}
                            alt={booster.name}
                        />
                        <p>
                            {getDailyBoosterDisplayName(booster)}
                            {getDailyBoosterCount(booster)}
                        </p>
                    </div>
                ))}
            </div>
            <p className="boost__title">Boosters</p>
            <div className="boost__regular">
                {data?.boosters.permanent.map((booster) => {
                    let icon;
                    switch (booster.name) {
                        case PermanentBoosterName.TOKEN_MULTIPLIER:
                            icon = multiplier;
                            break;
                        case PermanentBoosterName.INCREASE_MAX_BANANAS:
                            icon = increase;
                            break;
                        case PermanentBoosterName.BANANA_REFILL:
                            icon = refill;
                            break;
                        default:
                            icon = '';
                    }

                    return (
                        <div
                            key={booster.name}
                            className={`boost__booster ${activatingBooster === booster.name ? 'activating' : ''}`}
                            onClick={() => handleActivatePermanent(booster.name)}
                        >
                            <img className="boost__booster__icon" src={icon} alt={booster.name}/>
                            <div className="boost__booster__text">
                                <p>{booster.name}</p>
                                <p className="boost__booster__description">
                                    <img className="boost__booster__token" src={chimpSmall} alt="Chimp small"/>
                                    &nbsp;<span className="boost__booster__gold">{booster.price}</span>&nbsp;
                                    <span>&nbsp;/&nbsp;Level {booster.level}</span>
                                </p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Boost;
