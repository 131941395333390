import { FallingImage } from './types';
import tokenRegularSrc from '../../assets/images/TokenRegular.svg';
import tokenSpecialSrc from '../../assets/images/TokenSpecial.svg';

export const createFallingImage = (canvasWidth: number): FallingImage => {
    const x = Math.random() * (canvasWidth - 100);
    const y = -100;
    const speed = Math.random() * 4 + 2;
    const angle = (Math.random() * (135 - 45) + 45) * Math.PI / 180;
    const dx = Math.cos(angle) * speed;
    const dy = Math.sin(angle) * speed;

    const isSpecialToken = Math.random() < 0.03;

    const image = new Image();
    image.src = isSpecialToken ? tokenSpecialSrc : tokenRegularSrc;

    return {
        x,
        y,
        speed,
        angle,
        dx,
        dy,
        image,
        loaded: false,
        isSpecialToken,
        draw(ctx: CanvasRenderingContext2D) {
            ctx.drawImage(this.image, this.x, this.y, 100, 100);
        },
        update(canvasWidth: number) {
            this.x += this.dx;
            this.y += this.dy;

            if (this.x < 0 || this.x + 100 > canvasWidth) {
                this.dx = -this.dx;
            }

            if (this.x < 0) {
                this.x = 0;
            } else if (this.x + 100 > canvasWidth) {
                this.x = canvasWidth - 100;
            }
        }
    };
};
