import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Frens.scss';
import Score from '../Score/Score';
import invite from '../../assets/images/invite.png';
import invitePremium from '../../assets/images/invite-premium.png';
import chimpSmall from '../../assets/images/chimp-small.png';
import chimpSmallInvite from '../../assets/images/small-chimp-icon.png';
import inviteFrens from '../../assets/images/invite-frens-icon.png';
import inviteSingleFren from '../../assets/images/invite-single-fren-icon.png';
import backIcon from '../../assets/images/back.png';
import { useAxios } from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import LoadingError from '../LoadingError/LoadingError';
import { useUserPoints } from '../../contexts/UserPointsContext';
import iconReferral from '../../assets/images/icon-referral.png';
import iconLeaderboard from '../../assets/images/icon-leaderboard.png';
import Leaderboard from '../Leaderboard/Leaderboard';

interface Referral {
    ref_username: string;
    ref_points: number;
    ref_reward: number;
}

interface ReferralData {
    number_of_referrals: number;
    points: number;
    referrals: Referral[];
    uid: string;
}

interface UserResponse {
    user: {
        uid: string;
        username: string;
        points: number;
        referral_data: {
            referral_ink: string;
            referrals: string[];
        };
    };
}

interface LeaderboardEntry {
    Points: number;
    Rank: number;
    Uid: string;
    Username: string;
}

interface LeaderboardResponse {
    leaderboard: LeaderboardEntry[];
    uid: string;
}

const Frens: React.FC = () => {
    const navigate = useNavigate();
    const [referralData, setReferralData] = useState<ReferralData | null>(null);
    const [userData, setUserData] = useState<UserResponse | null>(null);
    const [leaderboardData, setLeaderboardData] = useState<LeaderboardResponse | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const axiosInstance = useAxios();
    const { updatePoints } = useUserPoints();
    const [activeTab, setActiveTab] = useState<'referrals' | 'leaderboard'>('referrals');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [userResponse, referralResponse, leaderboardResponse] = await Promise.all([
                    axiosInstance.get<UserResponse>('/user'),
                    axiosInstance.get<ReferralData>('/user/referral/all'),
                    axiosInstance.get<LeaderboardResponse>('/user/leaderboard')
                ]);
                setUserData(userResponse.data);
                setReferralData(referralResponse.data);
                setLeaderboardData(leaderboardResponse.data);
                updatePoints(userResponse.data.user.points);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                toast.error('Failed to load data.');
                setLoading(false);
            }
        };

        void fetchData();
    }, [axiosInstance, updatePoints]);

    const handleGoBack = () => {
        navigate(-1);
    };

    const renderInviteBonus = (image: string, text: string, bonus: number) => (
        <div className="frens__rewards__bonus">
            <img className="frens__rewards__icon" src={image} alt="Invite"/>
            <div className="frens__rewards__text">
                <p>{text}</p>
                <p className="frens__rewards__description">
                    <img className="frens__rewards__token" src={chimpSmall} alt="Chimp small"/>
                    <span className="frens__rewards__gold"> + {bonus.toLocaleString()}</span>
                    <span className="frens__rewards__text--small"> / For you and your FREN!</span>
                </p>
            </div>
        </div>
    );

    const handleInvite = () => {
        if (userData && userData.user.referral_data.referral_ink) {
            navigator.clipboard.writeText(userData.user.referral_data.referral_ink).then(() => {
                toast.success('Invite link copied to clipboard!');
            }).catch((err) => {
                console.error('Failed to copy: ', err);
                toast.error('Failed to copy invite link.');
            });
        } else {
            toast.error('Invite link not available.');
        }
    };

    const handleInviteMoreChimps = () => {
        if (userData && userData.user.referral_data.referral_ink) {
            const shareText = "Guess what? TON Chimp has developed an incredibly cool Telegram mini app and no chimps were harmed in the process! Perform quick tasks, earn rewards, and learn as you go.\n\nJoin the TON Chimp community now and start earning while learning!";
            const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(userData.user.referral_data.referral_ink)}&text=${encodeURIComponent(shareText)}`;
            window.open(telegramShareUrl, '_blank');
        } else {
            toast.error('Invite link not available.');
        }
    };

    const renderFriendsList = () => {
        if (!referralData || referralData.number_of_referrals === 0) {
            return <p>You haven't invited anyone yet :(</p>;
        }

        return referralData.referrals.slice(0, 3).map((referral, index) => (
            <div key={index} className="frens__list__item">
                <div className="frens__list__item-left">
                    <img src={chimpSmallInvite} alt="Chimp" className="frens__list__item-image"/>
                    <p className="frens__list__item-name">{referral.ref_username}</p>
                </div>
                <div className="frens__list__item-right">
                    <img src={chimpSmall} alt="Coin" className="frens__list__item-coin"/>
                    <p className="frens__list__item-points">{(referral.ref_points / 1000).toFixed(1)}K</p>
                </div>
            </div>
        ));
    };

    const renderReferralsContent = () => (
        <>
            <div className="frens__invite">
                <div className="frens__invite-cta" onClick={handleInviteMoreChimps}>
                    <img src={inviteSingleFren} className="frens__invite-icon" alt="Cta 1"/>
                    <p>Invite more chimps!</p>
                </div>
                <div className="frens__invite-symbol" onClick={handleInvite}>
                    <img src={inviteFrens} className="frens__invite-icon" alt="Cta 2"/>
                </div>
            </div>
            <div className="frens__rewards">
                {renderInviteBonus(invite, 'Invite a chimp!', 10000)}
                {renderInviteBonus(invitePremium, 'Invite a TG premium chimp!', 25000)}
            </div>
            <p className="text-center mb-4">More bonuses</p>
            <div className="frens__list">
                <p className="frens__list__text">Chimp friends list
                    ({userData?.user.referral_data.referrals.length ?? 0})</p>
                <div className="frens__list__container">
                    {renderFriendsList()}
                </div>
            </div>
        </>
    );

    if (loading || error) return <LoadingError loading={loading} error={error}/>;

    return (
        <div className="frens">
            <Score/>
            <div className="frens__header">
                <h1>Invite more Chimps!</h1>
                <p>Both you and friends will receive bonuses</p>
            </div>
            <div className="frens__back" onClick={handleGoBack}>
                <img src={backIcon} alt="Back" className="frens__back-icon"/>
                <p>Go back to main menu</p>
            </div>
            <nav className="frens__navigation">
                <button
                    className={`frens__navigation-item ${activeTab === 'referrals' ? 'frens__navigation-item--active' : ''}`}
                    onClick={() => setActiveTab('referrals')}
                >
                    <img src={iconReferral} alt="Referrals" className="frens__navigation-icon"/>
                    <span>Referrals</span>
                </button>
                <button
                    className={`frens__navigation-item ${activeTab === 'leaderboard' ? 'frens__navigation-item--active' : ''}`}
                    onClick={() => setActiveTab('leaderboard')}
                >
                    <img src={iconLeaderboard} alt="Leaderboard" className="frens__navigation-icon"/>
                    <span>Leaderboard</span>
                </button>
            </nav>
            {activeTab === 'referrals' ? renderReferralsContent() :
                (leaderboardData ? <Leaderboard data={leaderboardData} /> : <LoadingError loading={true} error={null} />)}
        </div>
    );
};

export default Frens;
