import React, { useState, useEffect, useCallback } from 'react';
import './SpecialGameModal.scss';
import TapChallenge from './TapChallenge';
import lowResBackground from '../../assets/images/low-res-special-game-background.png';
import highResBackground from '../../assets/images/special-game-background.png';
import chimpImage from '../../assets/images/chimp-medium.png';

interface SpecialGameModalProps {
    onClose: (score: number) => void;
    multiplier: number;
}

const SpecialGameModal: React.FC<SpecialGameModalProps> = ({ onClose, multiplier }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [showTapChallenge, setShowTapChallenge] = useState(false);
    const [isHighResLoaded, setIsHighResLoaded] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 10);

        const img = new Image();
        img.src = highResBackground;
        img.onload = () => {
            setIsHighResLoaded(true);
        };

        return () => clearTimeout(timer);
    }, []);

    const handleClose = useCallback((score: number) => {
        setIsVisible(false);
        setTimeout(() => onClose(score), 600);
    }, [onClose]);

    const startTapChallenge = useCallback(() => {
        setShowTapChallenge(true);
    }, []);

    return (
        <div className={`special-game-modal ${isVisible ? 'visible' : ''}`}
             style={{
                 backgroundImage: `url(${isHighResLoaded ? highResBackground : lowResBackground})`,
             }}>
            <div className="special-game-modal__content">
                {!showTapChallenge ? (
                    <div className="special-game-modal__intro">
                        <img onClick={startTapChallenge} src={chimpImage} alt="Chimp" className="special-game-modal__chimp-image" />
                        <button onClick={startTapChallenge} className="special-game-modal__start-button">
                            Start Tap Challenge
                        </button>
                    </div>
                ) : (
                    <TapChallenge onFinish={(score) => handleClose(score)} multiplier={multiplier} />
                )}
            </div>
        </div>
    );
};

export default SpecialGameModal;
