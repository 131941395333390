import React from 'react';
import './Leaderboard.scss';
import goldMedal from '../../assets/images/gold.png';
import silverMedal from '../../assets/images/silver.png';
import bronzeMedal from '../../assets/images/bronze.png';

interface LeaderboardEntry {
    Points: number;
    Rank: number;
    Uid: string;
    Username: string;
}

interface LeaderboardProps {
    data: {
        leaderboard: LeaderboardEntry[];
        uid: string;
    };
}

const Leaderboard: React.FC<LeaderboardProps> = ({ data }) => {
    const getMedalImage = (position: number): string | undefined => {
        switch (position) {
            case 1:
                return goldMedal;
            case 2:
                return silverMedal;
            case 3:
                return bronzeMedal;
            default:
                return undefined;
        }
    };

    const renderLeaderboardRows = () => {
        let displayEntries = [...data.leaderboard];
        const needsSeparator = data.leaderboard.length === 6;

        if (needsSeparator) {
            displayEntries = [
                ...data.leaderboard.slice(0, 3),
                { Points: 0, Rank: 0, Uid: 'separator', Username: '' },
                ...data.leaderboard.slice(3)
            ];
        }

        return displayEntries.map((entry, index) => (
            <tr key={entry.Uid} className={`${entry.Uid === data.uid ? 'leaderboard__highlighted' : ''} ${index < 3 ? `leaderboard__top-${index + 1}` : ''}`}>
                <td>
                    {entry.Uid === 'separator' ? (
                        ''
                    ) : entry.Rank <= 3 ? (
                        <img src={getMedalImage(entry.Rank)} alt={`Rank ${entry.Rank}`} className="leaderboard__medal" />
                    ) : (
                        entry.Rank
                    )}
                </td>
                <td>{entry.Uid === 'separator' ? '...' : (entry.Username || entry.Uid)}</td>
                <td>{entry.Uid === 'separator' ? '' : entry.Points.toLocaleString()}</td>
            </tr>
        ));
    };

    return (
        <div className="leaderboard">
            <table className="leaderboard__table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Points</th>
                </tr>
                </thead>
                <tbody>
                {renderLeaderboardRows()}
                </tbody>
            </table>
        </div>
    );
};

export default Leaderboard;
