import React, { useEffect, useRef, useState, useCallback } from 'react';
import gameMusicSrc from '../../assets/sounds/game.mp3';
import coinSoundSrc from '../../assets/sounds/coin-sound.mp3';
import './Farm.scss';
import banana from '../../assets/images/banana.png';

import { useAxios } from '../../hooks/useAxios';
import { toast } from 'react-toastify';
import LoadingError from '../LoadingError/LoadingError';
import { User } from './types';
import { useGameLogic } from './useGameLogic';
import { useAudio } from './useAudioLogic';
import Score from '../Score/Score';
import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';
import { useInterval } from './useInterval';
import SpecialGameModal from './SpecialGameModal';
import { useUserPoints } from '../../contexts/UserPointsContext';
import { useLocation } from 'react-router-dom';

const Farm: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [totalScore, setTotalScore] = useState(0);
    const [maxEnergy, setMaxEnergy] = useState(0);
    const [energyRefillRate, setEnergyRefillRate] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [user, setUser] = useState<User | null>(null);
    const axiosInstance = useAxios();
    const [initialEnergy, setInitialEnergy] = useState(0);
    const [multiplier, setMultiplier] = useState(1);
    const [showSpecialGameModal, setShowSpecialGameModal] = useState(false);
    const { updatePoints } = useUserPoints();
    const location = useLocation();

    const {
        currentUserScore,
        setCurrentUserScore,
        currentEnergy,
        sliceImage,
        updateUserGame,
        fallingImages,
        specialCoinsCollected,
        resetSpecialCoinsCollected
    } = useGameLogic(
        maxEnergy,
        canvasRef,
        axiosInstance,
        totalScore,
        energyRefillRate,
        initialEnergy,
        multiplier
    );

    const {
        isSoundOn,
        toggleSound,
        playCoinSound,
        backgroundMusicRef,
        coinSoundRef
    } = useAudio(gameMusicSrc, coinSoundSrc);

    useEffect(() => {
        const handleBeforeUnload = () => {
            updateUserGame();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            updateUserGame();
        };
    }, [updateUserGame]);

    useInterval(updateUserGame, 5000);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userResponse = await axiosInstance.get<User>('/user/game');
                setUser(userResponse.data);
                setMaxEnergy(userResponse.data.max_energy);
                setEnergyRefillRate(userResponse.data.energy_refill_rate);
                setTotalScore(userResponse.data.points);
                updatePoints(userResponse.data.points);
                setInitialEnergy(userResponse.data.energy);
                setMultiplier(userResponse.data.multiplier);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error);
                toast.error('Failed to load data.');
                setLoading(false);
            }
        };

        fetchUser();
    }, [axiosInstance, updatePoints]);

    useEffect(() => {
        console.log('Red coins collected (Farm component):', specialCoinsCollected);
        if (specialCoinsCollected >= 10) {
            console.log('Showing modal');
            setShowSpecialGameModal(true);
        }
    }, [specialCoinsCollected]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const activateSpecialGame = searchParams.get('activateSpecialGame');

        if (activateSpecialGame === 'true') {
            setShowSpecialGameModal(true);
            // Remove the query parameter after activating
            searchParams.delete('activateSpecialGame');
            window.history.replaceState({}, '', `${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`);
        }
    }, [location]);

    const handleCanvasEvent = useCallback((event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
        console.log('Canvas event triggered');
        const rect = canvasRef.current!.getBoundingClientRect();
        const x = ('touches' in event ? event.touches[0].clientX : event.clientX) - rect.left;
        const y = ('touches' in event ? event.touches[0].clientY : event.clientY) - rect.top;

        const coinClicked = fallingImages.some(img =>
            x >= img.x && x <= img.x + 100 && y >= img.y && y <= img.y + 100
        );

        if (coinClicked) {
            sliceImage(x, y);
            playCoinSound();
        }
    }, [fallingImages, sliceImage, playCoinSound]);

    const handleCloseSpecialGameModal = useCallback((score: number) => {
        setShowSpecialGameModal(false);
        resetSpecialCoinsCollected();
        setCurrentUserScore(0);

        const updatedTotalScore = totalScore + currentUserScore + score;
        updatePoints(updatedTotalScore);

        axiosInstance.patch('/user/game', {
            points: updatedTotalScore,
            energy: currentEnergy,
        }).catch(error => console.error('Error updating user game data:', error));

    }, [resetSpecialCoinsCollected, axiosInstance, updatePoints, totalScore, currentUserScore, currentEnergy]);

    if (loading || error) return <LoadingError loading={loading} error={error}/>;

    return (
        <div className="game">
            <div className="game__header">
                <Score specialCoinsCollected={specialCoinsCollected} currentUserScore={currentUserScore}/>
                <div className="game__sound-toggle" onClick={toggleSound}>
                    {isSoundOn ? <FaVolumeUp size={40} className="game__sound-icon"/> :
                        <FaVolumeMute size={40} className="game__sound-icon"/>}
                </div>
            </div>
            <canvas ref={canvasRef} className="game__canvas"
                    onMouseDown={handleCanvasEvent}
                    onTouchStart={handleCanvasEvent}
            ></canvas>
            <div className="energy-bar-container">
                <div className="energy-text">
                    <img className="energy-banana" src={banana} alt="Banana"/>&nbsp;
                    <p><span className="energy-current">{currentEnergy}</span>/{maxEnergy}</p>
                </div>
                <div className="energy-container">
                    <div className="energy-bar"
                         style={{width: `${Math.min((currentEnergy / maxEnergy) * 100, 100)}%`}}/>
                </div>
            </div>
            <audio ref={backgroundMusicRef} src={gameMusicSrc} preload="auto" loop/>
            <audio ref={coinSoundRef} src={coinSoundSrc} preload="auto"/>
            {showSpecialGameModal && <SpecialGameModal onClose={handleCloseSpecialGameModal} multiplier={multiplier} />}
        </div>
    );
};

export default Farm;
