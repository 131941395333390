import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navigation.scss';
import tasks from '../../assets/images/tasks.png';
import farm from '../../assets/images/farm.png';
import boost from '../../assets/images/boost.png';
import frens from '../../assets/images/frens.png';

const Navigation: React.FC = () => {
    const location = useLocation();

    const isFarmActive = location.pathname === '/' || location.pathname === '/farm';
    const isFrensActive = location.pathname === '/frens';

    if (isFrensActive) {
        return null; // Hide navigation on FRENS tab
    }

    return (
        <nav className="navigation">
            <NavLink to="/tasks" className={({ isActive }) => isActive ? 'navigation__link navigation__link--active' : 'navigation__link'}>
                <img src={tasks} alt="Tasks" className="navigation__icon" />
                <span className="navigation__title">Tasks</span>
            </NavLink>
            <NavLink to="/farm" className={() => isFarmActive ? 'navigation__link navigation__link--active' : 'navigation__link'}>
                <img src={farm} alt="Farm" className="navigation__icon" />
                <span className="navigation__title">Farm</span>
            </NavLink>
            <NavLink to="/boost" className={({ isActive }) => isActive ? 'navigation__link navigation__link--active' : 'navigation__link'}>
                <img src={boost} alt="Boost" className="navigation__icon" />
                <span className="navigation__title">Boost</span>
            </NavLink>
            <NavLink to="/frens" className={({ isActive }) => isActive ? 'navigation__link navigation__link--active' : 'navigation__link'}>
                <img src={frens} alt="Frens" className="navigation__icon" />
                <span className="navigation__title">+FRENS</span>
            </NavLink>
        </nav>
    );
};

export default Navigation;
