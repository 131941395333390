import React, { useEffect, useState } from 'react';
import './SplashScreen.scss';
import lowResImage from '../../assets/images/splash-low-res.jpeg'
import highResImage from '../../assets/images/splash.png'
import {ClipLoader} from 'react-spinners';

const SplashScreen: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = highResImage;
        img.onload = () => {
            setIsLoaded(true);
        };
    }, []);

    return (
        <div
            className="splash-screen"
            style={{
                backgroundImage: `url(${isLoaded ? highResImage : lowResImage})`,
            }}
        >
            <div className="loading-spinner">
                <ClipLoader size={150} color='#FAC22F'/>
            </div>
            {isLoaded && null}
        </div>
    );
};

export default SplashScreen;
