import { useRef, useEffect, useState } from 'react';

export const useAudio = (backgroundMusicSrc: string, coinSoundSrc: string) => {
    const [isSoundOn, setIsSoundOn] = useState(false);
    const backgroundMusicRef = useRef<HTMLAudioElement>(null);
    const coinSoundRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        const sound = backgroundMusicRef.current;
        if (sound) {
            if (isSoundOn) {
                sound.play().catch(error => console.error('Failed to play background music:', error));
            } else {
                sound.pause();
            }
        }
    }, [isSoundOn]);

    const toggleSound = () => setIsSoundOn(prevState => !prevState);

    const playCoinSound = () => {
        if (coinSoundRef.current && isSoundOn) {
            coinSoundRef.current.play().catch(error => console.error('Failed to play coin sound:', error));
        }
    };

    return { isSoundOn, toggleSound, playCoinSound, backgroundMusicRef, coinSoundRef };
};
